import { enqueueFocus, tabbable } from '@odo/utils/tabbable';
import type { CSSProperties, HTMLAttributes, ReactNode } from 'react';
import { useRef, useState } from 'react';

const HIDDEN_STYLES: CSSProperties = {
  border: 0,
  clip: 'rect(0 0 0 0)',
  height: '1px',
  margin: '-1px',
  overflow: 'hidden',
  padding: 0,
  position: 'fixed',
  whiteSpace: 'nowrap',
  width: '1px',
  top: 0,
  left: 0,
};

const getButtonRole = () =>
  /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
    ? 'button'
    : undefined;

const FocusGuard = (props: HTMLAttributes<HTMLSpanElement>) => {
  const [role] = useState<'button' | undefined>(getButtonRole);

  return (
    <span
      tabIndex={0}
      role={role}
      aria-hidden={role ? undefined : true}
      style={HIDDEN_STYLES}
      {...props}
    />
  );
};

const FocusTrap = ({ children }: { children: ReactNode }) => {
  const contentRef = useRef<HTMLDivElement>(null);

  return (
    <>
      {/* focus guard: wraps shift-tab from first element back down to the last */}
      <FocusGuard
        onFocus={() => {
          if (!contentRef.current) return;
          const [last] = [...tabbable(contentRef.current)].reverse();
          if (last) enqueueFocus(last);
        }}
      />

      <div ref={contentRef}>{children}</div>

      {/* focus guard: wraps tab from last element back up to the first */}
      <FocusGuard
        onFocus={() => {
          if (!contentRef.current) return;
          const [first] = tabbable(contentRef.current);
          if (first) enqueueFocus(first);
        }}
      />
    </>
  );
};

export default FocusTrap;
