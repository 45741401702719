import { dateObjectToIso } from '@odo/utils/date';
import { FilterType, type FilterInterface } from './types';

export const applyFilterDefault = (filter: FilterInterface): unknown =>
  typeof filter.defaultValue === 'function'
    ? filter.defaultValue()
    : filter.defaultValue;

export const getFilterLabel = (filter: FilterInterface, idx: number) => {
  let string = filter.label;

  if (idx >= 3) return string;

  switch (filter.type) {
    case FilterType.text:
    case FilterType.search:
      string += filter.exact ? `: "${filter.value}"` : `: ${filter.value}`;
      break;
    case FilterType.select:
      string += filter.exact
        ? `: "${
            filter.options?.find(option => option.value === filter.value)?.key
          }"`
        : `: ${filter.value}`;
      break;
    case FilterType.boolean:
      string += !!filter.value ? ': enabled' : ': disabled';
      break;
    case FilterType.date:
      if (filter.value instanceof Date) {
        string += filter.exact
          ? `: "${dateObjectToIso(filter.value, true)}"`
          : filter.key === 'activeFromDate'
          ? ` >= ${dateObjectToIso(filter.value, true)}`
          : ` <= ${dateObjectToIso(filter.value, true)}`;
      }
      break;
    case FilterType.range:
      if (Array.isArray(filter.value)) {
        const [from, to] = filter.value;
        if (
          typeof from === 'string' &&
          from !== '' &&
          typeof to === 'string' &&
          to !== ''
        ) {
          string += `: R${from}-R${to}`;
        }
      }
      break;
    case FilterType.multiSelect:
      if (Array.isArray(filter.value)) {
        const selectedOptions = filter.value
          .map(filterValue => {
            if (filter.options) {
              const option = filter.options.find(
                option => option.value === filterValue
              );
              return option ? option.key : '';
            }
            return '';
          })
          .filter(e => e);
        string +=
          selectedOptions.length > 0 ? `: ${selectedOptions.toString()}` : '';
      }
      break;
    default:
  }

  return string;
};
