import Button from '@odo/components/elements/button';
import Card from '@odo/components/elements/card';
import { Checkbox } from '@odo/components/elements/form-fields';
import { Box, Flex, FlexItem } from '@odo/components/elements/layout';
import {
  FaChevronLeft as IconChevronLeft,
  FaChevronRight as IconChevronRight,
} from 'react-icons/fa';
import { MdDragIndicator as IconDrag } from 'react-icons/md';
import { ImagePreview } from '.';
import type { EditorProductImage } from '@odo/types/portal';
import ImageTypeOptions from '@odo/screens/deal/editor/images-and-videos/widgets/image-type-options';
import ImageActions from '@odo/screens/deal/editor/images-and-videos/widgets/image-actions';
import ImageLabel from '@odo/screens/deal/editor/images-and-videos/widgets/image-label';
import ImagePreviewPrimitive from '@odo/screens/deal/editor/widgets/images/image-preview';
import Tooltip from '@odo/components/widgets/tooltip';
import DropIndicator from '@odo/components/elements/drop-indicator';
import { useDragReorderImage } from '@odo/screens/deal/editor/images-and-videos/hooks';
import { useCallback } from 'react';
import { isNewId } from '@odo/utils/uuid';
import styled from '@odo/lib/styled';
import { breakpointsEm } from '@odo/contexts/theme/provider';

const PREVIEW_SIZE = 175;
const PREVIEW_DIMS = { width: PREVIEW_SIZE, height: PREVIEW_SIZE };

const ButtonRotatedOnMobile = styled(Button)`
  @media screen and (max-width: ${breakpointsEm[0]}em) {
    transform: rotate(90deg);
  }
`;

const ImageGridItem = ({
  image,
  isSelected,
  toggleSelected,
  canMoveLeft,
  canMoveRight,
  moveLeft,
  moveRight,
}: {
  image: EditorProductImage;
  isSelected: boolean;
  toggleSelected: (select: boolean) => void;
  canMoveRight: boolean;
  canMoveLeft: boolean;
  moveRight: () => void;
  moveLeft: () => void;
}) => {
  const preview = useCallback(
    () => (
      <ImagePreviewPrimitive
        url={image.url}
        file={image.file}
        dimensions={PREVIEW_SIZE}
      />
    ),
    [image.url, image.file]
  );

  const { draggableRef, dragHandleRef, dragEdge, dragState } =
    useDragReorderImage({
      id: image.id,
      allowedEdges: ['left', 'right'],
      previewDims: PREVIEW_DIMS,
      ...(!isNewId(image.id) && { preview }),
    });

  // position relative for placement of our drop indicator
  return (
    <Box position="relative">
      <Card
        ref={draggableRef}
        isSelected={isSelected}
        width="100%"
        style={{
          transition: 'transform 25ms ease',
          ...(dragState === 'dragging'
            ? { transform: 'scale(0.9)', opacity: '0.6' }
            : {}),
        }}
        header={
          <Flex justifyContent="space-between" width="100%">
            <Checkbox
              label=""
              checked={isSelected}
              onChange={e => toggleSelected(!!e.target.checked)}
            />

            <Flex gap={1}>
              <ImageActions image={image} />
            </Flex>
          </Flex>
        }
      >
        <Flex flexDirection="column" gap={[2, 3]}>
          <FlexItem alignSelf="center">
            <ImagePreview image={image} dimensions={PREVIEW_SIZE} />
          </FlexItem>

          <Flex width="100%" justifyContent="space-between">
            <Tooltip
              showDelay={1250}
              content={() => 'Move Left'}
              disabled={!canMoveLeft}
            >
              <ButtonRotatedOnMobile
                hue="dark-grey"
                variant={!canMoveLeft ? 'flat' : 'outlined'}
                disabled={!canMoveLeft}
                px={1}
                py={1}
                onClick={moveLeft}
              >
                <IconChevronLeft />
              </ButtonRotatedOnMobile>
            </Tooltip>

            <Tooltip
              showDelay={1250}
              content={() => 'Drag to Move'}
              disabled={dragState !== 'idle'}
            >
              <Button
                ref={dragHandleRef}
                hue="dark-grey"
                variant="flat"
                py={0}
                style={{ cursor: 'grab' }}
              >
                <IconDrag size={18} />
              </Button>
            </Tooltip>

            <Tooltip
              showDelay={1250}
              content={() => 'Move Right'}
              disabled={!canMoveRight}
            >
              <ButtonRotatedOnMobile
                hue="dark-grey"
                variant={!canMoveRight ? 'flat' : 'outlined'}
                disabled={!canMoveRight}
                px={1}
                py={1}
                onClick={moveRight}
              >
                <IconChevronRight />
              </ButtonRotatedOnMobile>
            </Tooltip>
          </Flex>

          <ImageLabel image={image} hideInputLabel />

          <ImageTypeOptions image={image} />
        </Flex>
      </Card>

      {dragEdge && <DropIndicator edge={dragEdge} gap={[2, 3]} />}
    </Box>
  );
};

export default ImageGridItem;
