import {
  InputNumericMasked,
  Select,
  Switch,
} from '@odo/components/elements/form-fields';
import { Flex, Grid } from '@odo/components/elements/layout';
import { useMemo } from 'react';
import { useAttributeOptions } from '@odo/hooks/attributes';
import { AttributeCode } from '@odo/types/api';
import type {
  SetterProps,
  ValueProps,
} from '@odo/screens/tools/cost-compare/types';

const PricingForm = ({
  cost,
  setCost,
  price,
  setPrice,
  originalStock,
  setOriginalStock,
  retail,
  setRetail,
  rebateDiscount,
  setRebateDiscount,
  surcharge,
  setSurcharge,
  adminCost,
  setAdminCost,
  vatIncluded,
  setVatIncluded,
}: ValueProps & SetterProps) => {
  const adminCostOptions = useAttributeOptions(AttributeCode.adminCost);

  // TODO: this is duplicated almost 100% from the deal editor... extract somewhere
  const adminCostOptionsFiltered = useMemo(
    () =>
      adminCostOptions.filter(
        option =>
          option.value === adminCost?.id ||
          !['none', 'admincost_0_00'].includes(option.value.toLowerCase())
      ),
    [adminCostOptions, adminCost?.id]
  );

  return (
    <Grid gap={[4, 5]} gridTemplateColumns={['1fr', 'repeat(2, 1fr)']}>
      <Flex gap="24px" flexDirection="column">
        <InputNumericMasked
          label="Cost"
          value={cost?.string || ''}
          type="currency"
          onChange={setCost}
          required
          selectOnFocus
        />

        <InputNumericMasked
          label="Price"
          value={price?.string || ''}
          type="currency"
          onChange={setPrice}
          required
          selectOnFocus
        />

        <InputNumericMasked
          label="Original Stock"
          value={originalStock?.string || ''}
          type="currency"
          onChange={setOriginalStock}
          required
          selectOnFocus
        />

        <InputNumericMasked
          label="Retail"
          value={retail?.string || ''}
          type="currency"
          onChange={setRetail}
          selectOnFocus
        />
      </Flex>

      <Flex gap="24px" flexDirection="column">
        <InputNumericMasked
          label="Rebate Discount %"
          value={rebateDiscount?.string || ''}
          type="decimal"
          onChange={setRebateDiscount}
          selectOnFocus
        />

        <InputNumericMasked
          label="Surcharge"
          value={surcharge?.string || ''}
          type="currency"
          onChange={setSurcharge}
          selectOnFocus
        />

        <Select
          label="Admin Cost"
          value={adminCost?.id || ''}
          onChange={e => {
            const value = e.target.value;
            const adminCost = adminCostOptions.find(
              adminCost => adminCost.value === value
            );
            setAdminCost(
              adminCost
                ? {
                    id: adminCost.value,
                    label: adminCost.label,
                  }
                : undefined
            );
          }}
          options={adminCostOptionsFiltered.map(option => ({
            id: option.value,
            value: option.value,
            label: option.label,
          }))}
          required
        />

        <Switch
          label="VAT Included"
          checked={vatIncluded}
          onChange={e => setVatIncluded(e.target.checked)}
        />
      </Flex>
    </Grid>
  );
};

export default PricingForm;
