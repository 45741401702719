import type { Dispatch, SetStateAction } from 'react';

export enum FilterType {
  text = 'text',
  date = 'date',
  select = 'select',
  multiSelect = 'multiSelect',
  boolean = 'boolean',
  range = 'range',
  search = 'search',
}

export enum FilterKey {
  brand = 'brand',
  activeFromDate = 'activeFromDate',
  cost = 'cost',
  id = 'id',
  name = 'name',
  activeToDate = 'activeToDate',
  price = 'price',
  status = 'status',
  sku = 'sku',
  buyer = 'buyer',
  categories = 'categories',
  listingPages = 'listingPages',
}

export interface FilterInterface {
  label: string;
  key: FilterKey;
  type: FilterType;
  active?: boolean;
  exact?: boolean;
  value?: unknown;
  step?: number;
  defaultValue?: FilterInterface['value'] | (() => FilterInterface['value']);
  options?: { key: string; value: string }[];
}

export interface SearchFiltersContextType {
  isReady: boolean;
  filters: Readonly<FilterInterface[]>;
  setFilters: Dispatch<SetStateAction<FilterInterface[]>>;
  isLoadingDeals: boolean;
  setIsLoadingDeals: Dispatch<SetStateAction<boolean>>;
}
