import Button from '@odo/components/elements/button';
import Card from '@odo/components/elements/card';
import { Fieldset, Switch } from '@odo/components/elements/form-fields';
import { Flex, Grid, GridItem } from '@odo/components/elements/layout';
import {
  FaTimes as IconClose,
  FaExternalLinkAlt as IconExternalLink,
  FaChevronRight as IconChevronRight,
  FaCalculator as IconSetInput,
  FaCertificate as IconTrusted,
} from 'react-icons/fa';
import { GoInfo as IconInfo } from 'react-icons/go';
import { Heading, Text } from '@odo/components/elements/typography';
import { cssColor } from '@odo/utils/css-color';
import type { Dispatch, SetStateAction } from 'react';
import { useMemo, useState } from 'react';
import Tooltip from '@odo/components/widgets/tooltip';
import { formatMoney } from '@odo/utils/currency';
import type {
  DisplayedResult,
  NumericInput,
} from '@odo/screens/tools/cost-compare/types';
import {
  GOOGLE_IMAGE_DIMS_PX,
  SCRAPE_PAGE_COUNT,
} from '@odo/screens/tools/cost-compare/constants';
import styled from '@odo/lib/styled';

const DomainCode = styled.code`
  overflow-wrap: break-word;
  color: ${cssColor('text-muted')};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const roundRetail = (retail: number) => {
  const factor = retail < 1000 ? 5 : retail < 10000 ? 50 : 500;
  return Math.round(retail / factor) * factor;
};

export interface CompareResultsInheritedProps {
  setRetail: Dispatch<SetStateAction<NumericInput>>;
}

const CompareResults = ({
  results,
  lastPageLoaded,
  setRetail,
}: CompareResultsInheritedProps & {
  results: DisplayedResult[];
  lastPageLoaded?: number;
}) => {
  const [requireImage, setRequireImage] = useState(false);
  const [requirePrice, setRequirePrice] = useState(false);
  const [requireLocal, setRequireLocal] = useState(false);

  const [excludedIds, setExcludedIds] = useState<DisplayedResult['uuid'][]>([]);

  const filteredResults = useMemo(
    () =>
      results.filter(
        res =>
          !excludedIds.includes(res.uuid) &&
          (!requireImage || res.image) &&
          (!requirePrice || res.price) &&
          // TODO: remove this, and instead filter out results from foreign domains (country or continent)
          (!requireLocal || res.domain.indexOf('.co.za') !== -1)
      ),
    [results, excludedIds, requireImage, requirePrice, requireLocal]
  );

  const hasSomeImages = useMemo(
    () => filteredResults.some(res => res.image),
    [filteredResults]
  );

  const { lowestPrice, highestPrice, averagePriceRounded } = useMemo(() => {
    let lowest: number | undefined;
    let highest: number | undefined;

    filteredResults.forEach(res => {
      if (!res.price) return;

      if (typeof lowest === 'undefined' || res.price.number < lowest) {
        lowest = res.price.number;
      }

      if (typeof highest === 'undefined' || res.price.number > highest) {
        highest = res.price.number;
      }
    });

    const withPrices = filteredResults.filter(r => r.price);
    const ave =
      withPrices.reduce((a, b) => a + (b.price?.number || 0), 0) /
      withPrices.length;

    const aveRounded = roundRetail(ave);

    return {
      lowestPrice: lowest
        ? { string: formatMoney(lowest), number: lowest }
        : undefined,
      highestPrice: highest
        ? { string: formatMoney(highest), number: highest }
        : undefined,
      averagePrice:
        ave > 0
          ? {
              string: formatMoney(ave),
              number: ave,
              count: withPrices.length,
            }
          : undefined,
      averagePriceRounded:
        ave > 0
          ? {
              string: formatMoney(aveRounded, { decimals: 0 }),
              number: aveRounded,
              count: withPrices.length,
            }
          : undefined,
    };
  }, [filteredResults]);

  return (
    <>
      <Fieldset>
        <legend>Customize</legend>

        <Grid
          gap={[3, 4]}
          gridTemplateColumns={['1fr', 'auto auto auto']}
          justifyContent="flex-start"
        >
          <Switch
            label="Require Image"
            checked={requireImage}
            onChange={e => setRequireImage(e.target.checked)}
          />

          <Switch
            label="Require Price"
            checked={requirePrice}
            onChange={e => setRequirePrice(e.target.checked)}
          />

          <Switch
            label="Require CO.ZA"
            checked={requireLocal}
            onChange={e => setRequireLocal(e.target.checked)}
          />
        </Grid>
      </Fieldset>

      <Fieldset>
        <legend>Prices</legend>

        <Flex
          gap={[3, 4]}
          flexDirection={['column', 'row']}
          justifyContent={['flex-start', 'space-between']}
        >
          <Flex gap={[1, 2]} alignItems="center">
            {!!averagePriceRounded && (
              <Tooltip
                content={() =>
                  `Average price based on ${averagePriceRounded.count} result(s) and then rounded off.`
                }
              >
                <IconInfo size={18} color={cssColor('palette-blue')} />
              </Tooltip>
            )}

            <Text>Average:</Text>
            <Heading fontSize={[1, 2]} lineHeight="1.6rem">
              {!averagePriceRounded ? 'N/A' : `${averagePriceRounded.string}`}
            </Heading>

            {!!averagePriceRounded && (
              <Text>({averagePriceRounded.count})</Text>
            )}

            {!!averagePriceRounded && (
              <Tooltip content={() => 'Use in price calculator'}>
                <Button
                  hue="blue"
                  variant="outlined"
                  gap={1}
                  px={2}
                  py={1}
                  onClick={() =>
                    setRetail({
                      string: averagePriceRounded.number.toString(),
                      number: averagePriceRounded.number,
                    })
                  }
                >
                  <IconChevronRight
                    size={12}
                    color={cssColor('palette-blue')}
                  />
                  <IconSetInput size={14} color={cssColor('palette-blue')} />
                </Button>
              </Tooltip>
            )}
          </Flex>

          <Flex gap={[3, 4]} alignItems="center">
            <Flex gap={[1, 2]} alignItems="center">
              <Text>Lowest:</Text>
              <Heading fontSize={[1, 2]} lineHeight="1.6rem">
                {!lowestPrice ? 'N/A' : lowestPrice.string}
              </Heading>
            </Flex>

            <Flex gap={[1, 2]} alignItems="center">
              <Text>Highest:</Text>
              <Heading fontSize={[1, 2]} lineHeight="1.6rem">
                {!highestPrice ? 'N/A' : highestPrice.string}
              </Heading>
            </Flex>
          </Flex>
        </Flex>
      </Fieldset>

      <Flex gap={[2, 3]} justifyContent={['space-between', 'flex-start']}>
        <Text>
          Results:{' '}
          <span style={{ fontWeight: 800 }}>{filteredResults.length}</span>
        </Text>

        {typeof lastPageLoaded !== 'undefined' && (
          <Text>
            Pages Scraped:{' '}
            <span style={{ fontWeight: 800 }}>
              {lastPageLoaded}/{SCRAPE_PAGE_COUNT}
            </span>
          </Text>
        )}
      </Flex>

      <Grid
        gap={[1, 2]}
        gridTemplateColumns="repeat(auto-fill, minmax(155px, 1fr))"
      >
        {filteredResults.map(res => (
          <Card
            key={res.uuid}
            height="auto"
            headerProps={{ px: 0, py: 0 }}
            header={
              <Flex
                justifyContent="space-between"
                alignItems="center"
                width="100%"
              >
                <a
                  href={res.url}
                  title={res.title}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button hue="dark-grey" variant="flat" px={[1, 2]}>
                    <IconExternalLink />
                  </Button>
                </a>

                {!!res.trusted && (
                  <IconTrusted size={18} color={cssColor('palette-blue')} />
                )}

                <Button
                  hue="dark-grey"
                  variant="flat"
                  px={[1, 2]}
                  onClick={() => setExcludedIds(ids => [...ids, res.uuid])}
                >
                  <IconClose />
                </Button>
              </Flex>
            }
            mainProps={{ position: 'relative', height: '100%' }}
          >
            <Grid
              gap={[2, 3]}
              alignContent="space-between"
              height="100%"
              gridTemplateRows="1fr auto"
            >
              <Grid gap={2} gridTemplateRows="auto 1fr">
                {hasSomeImages && (
                  <GridItem justifySelf="center">
                    <Flex
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="center"
                      width={`${GOOGLE_IMAGE_DIMS_PX}px`}
                      height={`${GOOGLE_IMAGE_DIMS_PX}px`}
                    >
                      {res.image ? (
                        <img
                          src={res.image}
                          alt={res.title}
                          style={{ width: 'auto', maxWidth: '100%' }}
                        />
                      ) : (
                        <span>no image</span>
                      )}
                    </Flex>
                  </GridItem>
                )}

                <Text style={{ overflowWrap: 'break-word' }}>{res.title}</Text>
              </Grid>

              <Grid gap={1}>
                <Heading fontSize={[1, 2]}>{res.price?.string}</Heading>
                <DomainCode>{res.domain}</DomainCode>
              </Grid>
            </Grid>
          </Card>
        ))}
      </Grid>
    </>
  );
};

export default CompareResults;
