import { getODOAdminUrl } from '@odo/utils/make-url';
import type { GridCategory, GridProduct } from './types';
import type { ApiProduct } from '@odo/types/api';
import { cacheGet } from '@odo/utils/cache';
import { IMAGE_CACHE_NAME } from './constants';
import type { GetProductInterface } from '@odo/types/api-new';

const splitCategories = (categories: GetProductInterface['categories']) =>
  (categories || []).reduce(
    (acc, { categoryId, categoryName, type, breadcrumb }) => {
      if (!categoryId) return acc;

      if (type === 'daily_shop') {
        acc.shops.push({ id: categoryId, categoryName, breadcrumb });
      } else if (type === 'permanent') {
        acc.permanentShops.push({ id: categoryId, categoryName, breadcrumb });
      } else {
        acc.categories.push({ id: categoryId, categoryName, breadcrumb });
      }

      return acc;
    },
    {
      categories: [] as GridCategory[],
      shops: [] as GridCategory[],
      permanentShops: [] as GridCategory[],
    }
  );

export const prepProductRow = (product: ApiProduct) => {
  const shopsAndCategories = splitCategories(product.categories);

  return {
    rowId: product.id,
    data: {
      id: product.id,
      status: product.status,
      image:
        product['thumbnail'] ||
        cacheGet({ cacheName: IMAGE_CACHE_NAME, cacheKey: product.id }),
      name: product.name,
      brand: product.brand,
      sku: product.sku,
      shops: shopsAndCategories.shops,
      categories: shopsAndCategories.categories,
      permanentShops: shopsAndCategories.permanentShops,
      cost: product.cost,
      price: product.price,
      retail: product.retail,
      qty: product['inventory']['qty'] || null,
      activeFromDate: product.activeFromDate || null,
      activeToDate: product.activeToDate || null,
      buyer: product.buyer || 'N/A',
      salesAssistant: product.salesAssistant || 'N/A',
      priority: product.priority || null,
      preview: product.preview || null,
      priceForecastUrl: getODOAdminUrl({
        path: `/price_calculator?deal=${product.id}`,
      }),
      isSampleReceived: product.isSampleReceived,
      isPhotographedByStudio: product.isPhotographedByStudio,
      dealType: product.dealType,
      campaign: product.campaign,
      xtdDaysConfirmed: product.xtdDaysConfirmed,
      xtdDaysRequested: product.xtdDaysRequested,
    },
  };
};
