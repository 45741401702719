import type { CategoryOrBreadcrumb } from '@odo/types/portal';
import type { Dispatch, SetStateAction } from 'react';

export enum LoadingMessageStatus {
  loading = 'loading',
  success = 'success',
  error = 'error',
}

export interface LoadingMessage {
  productId: string;
  label: string;
  status: LoadingMessageStatus;
}

export interface BulkEditChanges {
  enabled?: boolean;
  dailyShop?: CategoryOrBreadcrumb;
  activeFromDate?: string;
  activeToDate?: string;
  removeAllShops?: boolean;
}
export interface UploadDealsArgs {
  changes: BulkEditChanges;
  setLoadingMessages: Dispatch<SetStateAction<Record<string, LoadingMessage>>>;
  onAllDealsUploaded: () => void;
}
