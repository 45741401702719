import Button from '@odo/components/elements/button';
import { FaPhotoVideo as IconImage } from 'react-icons/fa';
import { IoIosLink as IconLink } from 'react-icons/io';
import { ReactComponent as IconZoom } from '@odo/assets/svg/magnifying-glass.svg';
import type { BaseEditorImage } from '@odo/types/portal';
import {
  getImageSrc,
  isAnchorElement,
  downloadImage,
} from '@odo/screens/deal/editor/images-and-videos/helpers';
import Tooltip from '@odo/components/widgets/tooltip';
import { useCallback, useRef, useState } from 'react';
import Dialog from '@odo/components/widgets/dialog';
import ImagePreview from '@odo/screens/deal/editor/widgets/images/image-preview';
import { Text } from '@odo/components/elements/typography';
import { cssColor } from '@odo/utils/css-color';
import { Box, Flex, Grid } from '@odo/components/elements/layout';
import { useResized } from '@odo/hooks/display';

export const ImageActionDownload = ({ image }: { image: BaseEditorImage }) => (
  <Tooltip showDelay={1250} content={() => 'Download Image'}>
    <Button
      hue="dark-grey"
      variant="flat"
      px={0}
      py={0}
      onClick={() => downloadImage(image)}
    >
      <IconImage size={16} />
    </Button>
  </Tooltip>
);

export const ImageActionOpenInNewTab = ({
  image,
}: {
  image: BaseEditorImage;
}) => (
  <Tooltip showDelay={1250} content={() => 'Open Image in a New Tab'}>
    <Button hue="dark-grey" variant="flat" px={0} py={0}>
      <a
        href={getImageSrc({ url: image.url, file: image.file })}
        onLoad={e =>
          e.target instanceof Element &&
          isAnchorElement(e.target) &&
          // image urls generated with createObjectURL need to be revoked and will have the `blob:` protocol
          e.target.href.startsWith('blob:') &&
          URL.revokeObjectURL(e.target.href)
        }
        target="_blank"
        rel="noopener noreferrer"
        style={{ color: 'inherit', display: 'inline-flex' }}
      >
        <IconLink size={16} />
      </a>
    </Button>
  </Tooltip>
);

type Dimensions = { w: number; h: number } | undefined;

const ZoomDialogInner = ({ image }: { image: BaseEditorImage }) => {
  const containerRef = useRef<HTMLDivElement | null>(null);

  const [imageDimensions, setImageDimensions] = useState<Dimensions>();
  const [containerDimensions, setContainerDimensions] = useState<Dimensions>();

  const onContainerResize = useCallback((entry: ResizeObserverEntry) => {
    setContainerDimensions({
      w: Math.floor(entry.contentRect.width),
      h: Math.floor(entry.contentRect.height),
    });
  }, []);

  useResized({
    ref: containerRef,
    callback: onContainerResize,
    observeFirstChild: true,
  });

  return (
    <Grid gap={[1, 2]}>
      <Box ref={containerRef}>
        <ImagePreview
          url={image.url}
          file={image.file}
          dimensions={800}
          retainImageSize
          onLoad={image =>
            setImageDimensions({
              w: image.naturalWidth,
              h: image.naturalHeight,
            })
          }
        />
      </Box>

      <Flex
        gap={[1, 2]}
        justifyContent={['flex-start', 'space-between']}
        flexDirection={['column', 'row']}
      >
        <Text color={cssColor('text-muted')} fontStyle="italic">
          Image dimensions:{' '}
          {imageDimensions
            ? `${imageDimensions.w} x ${imageDimensions.h} pixels`
            : `N/A`}
        </Text>

        <Text color={cssColor('text-muted')} fontStyle="italic">
          Container dimensions:{' '}
          {containerDimensions
            ? `${containerDimensions.w} x ${containerDimensions.h} pixels`
            : `N/A`}
        </Text>
      </Flex>
    </Grid>
  );
};

export const ImageActionZoom = ({ image }: { image: BaseEditorImage }) => {
  const [zoom, setZoom] = useState(false);

  return (
    <>
      <Tooltip showDelay={1250} content={() => 'Zoom into image'}>
        <Button
          hue="dark-grey"
          variant="flat"
          px={0}
          py={0}
          onClick={() => setZoom(true)}
        >
          <IconZoom width={16} height={16} />
        </Button>
      </Tooltip>

      {zoom && (
        <Dialog title="Preview" isOpen close={() => setZoom(false)}>
          <ZoomDialogInner image={image} />
        </Dialog>
      )}
    </>
  );
};
