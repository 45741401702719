import type {
  GridBoxProps,
  FlexBoxProps,
} from '@odo/components/elements/layout';
import { Grid, Flex, FlexItem } from '@odo/components/elements/layout';
import { cssColor } from '@odo/utils/css-color';
import type { ButtonProps } from '@odo/components/elements/button';
import Button from '@odo/components/elements/button';
import styled from '@odo/lib/styled';
import type { ReactNode } from 'react';
import { Text } from '@odo/components/elements/typography';

const ButtonStripWrapper = styled(Grid).attrs({ as: 'section' })`
  display: inline-grid;
`;

ButtonStripWrapper.defaultProps = {
  p: 1,
  gap: 1,
  gridAutoFlow: 'column',
  bg: cssColor('foreground-alt'),
  borderRadius: '32px',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: '#c2c2c2',
};

const ButtonStripLabel = styled.label<{ indent?: boolean }>`
  color: ${cssColor('text')};
  ${p => p.indent && `text-indent: 8px;`}
`;

interface ButtonStripOption {
  id: string | number;
  label: string;
  icon?: ReactNode;
  buttonProps?: Partial<ButtonProps>;
}

interface ButtonStripProps<T extends ButtonStripOption> {
  options: T[];
  selectedOption?: T;
  onSelect?: (option: T) => void;
  label?: string;
  caption?: string;
  rounded?: boolean;
  hue?: ButtonProps['hue'];
  containerProps?: FlexBoxProps;
  wrapperProps?: GridBoxProps;
}

const ButtonStrip = <T extends ButtonStripOption>({
  options,
  selectedOption,
  onSelect,
  label,
  caption,
  rounded,
  hue = 'grey',
  containerProps,
  wrapperProps,
}: ButtonStripProps<T>) => (
  <Flex flexDirection="column" gap={[null, 1]} width="100%" {...containerProps}>
    {label && <ButtonStripLabel indent={rounded}>{label}</ButtonStripLabel>}

    <ButtonStripWrapper
      {...(!rounded && { borderRadius: '4px' })}
      {...wrapperProps}
    >
      {options.map(option => (
        <Button
          key={option.id}
          hue={option.id === selectedOption?.id ? hue : 'dark-grey'}
          variant={option.id === selectedOption?.id ? 'solid' : 'flat'}
          onClick={() => onSelect && onSelect(option)}
          width="100%"
          overflow="hidden"
          py={1}
          {...(!rounded && { borderRadius: '4px', px: 2 })}
          {...option.buttonProps}
        >
          {option.icon}
          <span
            style={{
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
          >
            {option.label}
          </span>
        </Button>
      ))}
    </ButtonStripWrapper>

    {caption && (
      <Text
        color={cssColor('text-muted')}
        fontStyle="italic"
        textIndent={rounded ? 2 : undefined}
      >
        {caption}
      </Text>
    )}
  </Flex>
);

export default ButtonStrip;
