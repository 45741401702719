import { Grid } from '@odo/components/elements/layout';
import { Text } from '@odo/components/elements/typography';
import { useMemo } from 'react';
import {
  calcProfit,
  getAdminCostValue,
} from '@odo/helpers/calculations/profit/utils';
import { isNumber } from '@odo/types/guards';
import ProfitPotential from '@odo/components/widgets/profit-potential';
import type { ValueProps } from '@odo/screens/tools/cost-compare/types';

const ProfitCalculator = ({
  cost,
  price,
  originalStock,
  retail,
  rebateDiscount,
  surcharge,
  adminCost,
  vatIncluded,
}: ValueProps) => {
  const profit = useMemo(() => {
    if (
      !isNumber(cost?.number) ||
      !isNumber(price?.number) ||
      !isNumber(originalStock?.number)
    ) {
      return;
    }

    /**
     * TODO: remove `|| 0` after upgrading to TS >= 5.3
     */
    return calcProfit({
      cost: cost?.number || 0,
      price: price?.number || 0,
      retail: retail?.number || undefined,
      stock: originalStock?.number || 0,
      rebate: rebateDiscount?.number || 0,
      surcharge: surcharge?.number || 0,
      adminCost: getAdminCostValue(adminCost?.id),
      vatIncluded,
    });
  }, [
    cost?.number,
    price?.number,
    retail?.number,
    originalStock?.number,
    rebateDiscount?.number,
    surcharge,
    adminCost?.id,
    vatIncluded,
  ]);

  return (
    <Grid gap="24px">
      {!profit && (
        <Text>
          Profit potential will be calculated after filling in cost, price, and
          original stock.
        </Text>
      )}

      {!!profit && (
        <ProfitPotential
          profit={profit}
          openMarginsByDefault
          displayCustomerSavings
        />
      )}
    </Grid>
  );
};

export default ProfitCalculator;
