import uuid from '@odo/utils/uuid';
import { formatMoney } from '@odo/utils/currency';
import {
  COMPARE_BLACKLIST,
  COMPARE_WHITELIST_PRIMARY,
  COMPARE_WHITELIST_SECONDARY,
} from './constants';

export interface DisplayedResult {
  uuid: string;
  url: string;
  domain: string;
  title: string;
  image?: string;
  price?: {
    string: string;
    number: number;
  };
  trusted?: 'primary' | 'secondary';
}

// NOTE:: the google search results use a weird currency formatting, eg. `R 19 689,00`
interface OrganicSearchResult {
  url: string;
  title: string;
  additional_info?: string[]; // price is buried in here (if it exists at all)
  /**
   * NOTE: sometimes google just says no and doesn't send images.
   * any image that isn't a full URL tends to be a data image JPEG.
   */
  images?: string[];
  favicon_text?: string;
  pos?: number;
  desc?: string;
  rating?: number;
  url_shown?: string;
  pos_overall?: number;
  review_count?: number;
}

type UnknownOrganicSearchResult = OrganicSearchResult | unknown;

interface ScraperResult<T> {
  content: { results: T };
}

export interface GoogleSearchResult {
  organic: UnknownOrganicSearchResult[];
}

interface ScraperResponse {
  job: unknown;
  results: [ScraperResult<GoogleSearchResult>];
}

export const isValidScraperResponse = (
  res: ScraperResponse | unknown
): res is ScraperResponse =>
  typeof (res as ScraperResponse) === 'object' &&
  Array.isArray((res as ScraperResponse).results) &&
  typeof (res as ScraperResponse).results[0] === 'object' &&
  typeof (res as ScraperResponse).results[0].content === 'object' &&
  typeof (res as ScraperResponse).results[0].content.results === 'object' &&
  Array.isArray((res as ScraperResponse).results[0].content.results.organic);

// should match any currency format as long as there is something before the numbers
// but only works if the decimals are separated with a comma
// we use this to get a products pricing, and exclude it if it's not using ZAR
const currencyRegex = new RegExp(
  /^([^0-9]+)(\s)?(0|[1-9][0-9]{0,2})(\s?\d{1,3})*(,\d{0,2})?$/
);

const hasInvalidCurrency = (res: OrganicSearchResult) => {
  // wrapped in a try catch just in case (coz this is used in the type guard)
  try {
    if (
      typeof res.additional_info === 'undefined' ||
      res.additional_info.length === 0
    ) {
      return false;
    }

    const matches = res.additional_info
      .find(i => i.match(currencyRegex))
      ?.match(currencyRegex);

    // if there is a currency symbol and it isn't ours, then this is invalid
    return matches && matches[1].trim() !== 'R' ? true : false;
  } catch {
    return false;
  }
};

export const isValidOrganicSearchResult = (
  res: UnknownOrganicSearchResult
): res is OrganicSearchResult =>
  typeof (res as OrganicSearchResult) === 'object' &&
  typeof (res as OrganicSearchResult).url === 'string' &&
  typeof (res as OrganicSearchResult).title === 'string' &&
  // images is undefined or an array of strings
  (typeof (res as OrganicSearchResult).images === 'undefined' ||
    (Array.isArray((res as OrganicSearchResult).images) &&
      ((res as OrganicSearchResult).images || []).every(
        i => typeof i === 'string'
      ))) &&
  // additional info is undefined or an array of strings
  (typeof (res as OrganicSearchResult).additional_info === 'undefined' ||
    (Array.isArray((res as OrganicSearchResult).additional_info) &&
      ((res as OrganicSearchResult).additional_info || []).every(
        i => typeof i === 'string'
      ))) &&
  // currency isn't invalid (after additional info so we can safely trust that we have a valid data structure)
  !hasInvalidCurrency(res as OrganicSearchResult) &&
  // domain isn't blacklisted
  !COMPARE_BLACKLIST.some(domain =>
    (res as OrganicSearchResult).url.startsWith(domain)
  );

const domainRegex = new RegExp(
  /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:/\n?]+)/
);

const randsRegex = new RegExp(
  /^R\s?(0|[1-9][0-9]{0,2})(?:\s?)(\d{1,3})*(,\d{0,2})?$/
);

export const transformOrganicToDisplay = (
  res: OrganicSearchResult
): DisplayedResult => {
  const domainMatches = res.url.match(domainRegex);
  const domain = domainMatches && domainMatches[1] ? domainMatches[1] : res.url;

  const rawPrice = (res.additional_info || [])
    .find(i => i.match(randsRegex))
    ?.match(randsRegex);

  let price: DisplayedResult['price'];
  if (rawPrice) {
    // remove undefined and skip the first item which is the raw string
    const [, ...pieces] = rawPrice.filter(p => typeof p !== 'undefined');
    // find and remove our decimal value
    const decimalIndex = pieces.findIndex(p => p.trim().startsWith(','));
    let decimal: string | undefined;
    if (decimalIndex !== -1) {
      decimal = pieces.splice(decimalIndex, 1)[0].replace(',', '.');
    }
    // combine our remaining pieces and the cleaned up decimal, then parse and format
    const priceString = [...pieces, decimal].join('');
    const priceNumber = parseFloat(priceString);
    price = { string: formatMoney(priceNumber), number: priceNumber };
  }

  const rawImage = res.images?.[0];
  const image = !rawImage
    ? undefined
    : rawImage.match(domainRegex)
    ? rawImage
    : `data:image/jpeg;base64,${rawImage}`;

  const trusted = COMPARE_WHITELIST_PRIMARY.includes(domain)
    ? 'primary'
    : COMPARE_WHITELIST_SECONDARY.includes(domain)
    ? 'secondary'
    : undefined;

  return {
    uuid: uuid(),
    title: res.title,
    url: res.url,
    image,
    domain,
    price,
    trusted,
  };
};

export interface NumericInput {
  string?: string;
  number?: number | null;
}

export interface ValueProps {
  cost: NumericInput;
  price: NumericInput;
  originalStock: NumericInput;
  retail: NumericInput;
  rebateDiscount: NumericInput;
  surcharge: NumericInput;
  adminCost?: { id: string; label?: string };
  vatIncluded: boolean;
}

type Setter<T extends keyof ValueProps> = (set: ValueProps[T]) => void;

export interface SetterProps {
  setCost: Setter<'cost'>;
  setPrice: Setter<'price'>;
  setOriginalStock: Setter<'originalStock'>;
  setRetail: Setter<'retail'>;
  setRebateDiscount: Setter<'rebateDiscount'>;
  setSurcharge: Setter<'surcharge'>;
  setAdminCost: Setter<'adminCost'>;
  setVatIncluded: Setter<'vatIncluded'>;
}
