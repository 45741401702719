import { Grid } from '@odo/components/elements/layout';
import type { ReactNode } from 'react';
import { useEffect, useCallback, useState } from 'react';
import SideEffects from '@odo/screens/deal/editor/side-effects';
import { ProductEditorProvider } from '@odo/contexts/product-editor';
import { useSetProduct } from '@odo/contexts/product-new';
import {
  ProductSource,
  useSetProduct as useOldSetProduct,
} from '@odo/contexts/product';
import Card from '@odo/components/elements/card';
import PreventLosingChanges from '@odo/screens/deal/editor/widgets/prevent-losing-changes';
import type { EditorProductInterface } from '@odo/types/portal';
import PersistDraftChanges from '@odo/screens/deal/editor/widgets/persist-draft-changes';
import { useDealId } from '@odo/screens/deal/editor/hooks';
import type { ProductLoaderResponse } from '@odo/screens/deal/editor/widgets/product-loader';
import {
  DraftLoader,
  ProductLoader,
} from '@odo/screens/deal/editor/widgets/product-loader';
import ToolDrawer from '@odo/screens/deal/editor/widgets/tool-drawer';
import { useLocation } from 'react-router-dom';

const EditorRoot = ({ children }: { children: ReactNode }) => {
  const setProduct = useSetProduct();
  const oldSetProduct = useOldSetProduct(); // this is for custom options only (for now)

  const { id, isDraft } = useDealId();

  const [isLoading, setIsLoading] = useState(true);

  const setDraft = useCallback(
    (draftProductData: EditorProductInterface) => {
      setProduct(draftProductData);

      // TODO: move the custom options onto our new product context once we fully use the new deal editor
      if (id) {
        oldSetProduct({
          // NOTE: we add the product price for our custom options to pull from
          product: { price: draftProductData.price?.number || undefined },
          source: ProductSource.portal,
          id: `tmp-${id}`,
        });
      }

      setIsLoading(false);
    },
    [id, oldSetProduct, setProduct]
  );

  const setEditorProduct = useCallback(
    ({ editorProduct, rawProductData }: ProductLoaderResponse) => {
      setProduct(editorProduct);

      // TODO: move the custom options onto our new product context once we fully use the new deal editor
      if (rawProductData.product?.id) {
        oldSetProduct({
          // NOTE: we add the product price for our custom options to pull from
          product: { price: rawProductData.product.price },
          source: ProductSource.magento,
          id: rawProductData.product.id,
          customOptions: rawProductData.customOptions,
        });
      }

      setIsLoading(false);
    },
    [oldSetProduct, setProduct]
  );

  if (isLoading) {
    return (
      <Grid
        gap={[2, 3]}
        justifyContent="center"
        alignContent="center"
        height="80vh"
      >
        <Card maxWidth="90vw">
          {isDraft ? (
            <DraftLoader id={id} callback={setDraft} />
          ) : (
            <ProductLoader id={id} callback={setEditorProduct} />
          )}
        </Card>
      </Grid>
    );
  }

  return (
    <ProductEditorProvider>
      <SideEffects />
      <PreventLosingChanges />
      <PersistDraftChanges />
      {children}
      <ToolDrawer />
      <ScrollToTop />
    </ProductEditorProvider>
  );
};

const ScrollToTop = () => {
  const { pathname } = useLocation();
  useEffect(() => window.scrollTo({ top: 0 }), [pathname]);
  return null;
};

/**
 * NOTE: this wrapper is used to ensure a completely new instance of the editor if the dealId param ever changes.
 * This can happen when the user navigates backwards and forwards in their browser history.
 */
const Wrapper = ({ children }: { children: ReactNode }) => {
  const { id } = useDealId();
  return <EditorRoot key={id}>{children}</EditorRoot>;
};

export default Wrapper;
