/**
 * Lighter version of npm package: tabbable.
 *
 * The tabbable package is designed to support as many browsers and DOMs as possible.
 * But for BP we have a limited browser list, and only one DOM type, so we can use a much lighter implementation.
 * We've removed support for shadow DOM here which made up a large portion of the extra effort implemented by tabbable.
 *
 * These utils are designed to mostly match how tabbable works, while using the more modern (and simple) APIs.
 *
 * @see https://github.com/focus-trap/tabbable/blob/master/src/index.js
 */

import { isHTMLElement } from '@odo/types/guards';

/**
 * NOTE: these type guards might be useful elsewhere.
 * For now I'm leaving them here.
 * If we ever decide we want to use them elsewhere then they should be moved to a different file.
 */
const isInputElement = (el: Element): el is HTMLInputElement =>
  el.tagName === 'INPUT';

const isButtonElement = (el: Element): el is HTMLButtonElement =>
  el.tagName === 'BUTTON';

const isSelectElement = (el: Element): el is HTMLSelectElement =>
  el.tagName === 'SELECT';

const isTextAreaElement = (el: Element): el is HTMLTextAreaElement =>
  el.tagName === 'TEXTAREA';

const isFieldSetElement = (el: Element): el is HTMLFieldSetElement =>
  el.tagName === 'FIELDSET';

const isLegendElement = (el: Element): el is HTMLLegendElement =>
  el.tagName === 'LEGEND';

const isDetailsElement = (el: Element): el is HTMLDetailsElement =>
  el.tagName === 'DETAILS';

const isLinkElement = (el: Element): el is HTMLLinkElement =>
  el.tagName === 'A';

const isOptGroupElement = (el: Element): el is HTMLOptGroupElement =>
  el.tagName === 'OPTGROUP';

const isOptionElement = (el: Element): el is HTMLOptionElement =>
  el.tagName === 'OPTION';

// NOTE: this can potentially be simplified for modern browsers, but impact is low and so is motivation ;)
const candidateSelectors = [
  'input:not([inert])',
  'select:not([inert])',
  'textarea:not([inert])',
  'a[href]:not([inert])',
  'button:not([inert])',
  '[tabindex]:not(slot):not([inert])',
  'audio[controls]:not([inert])',
  'video[controls]:not([inert])',
  '[contenteditable]:not([contenteditable="false"]):not([inert])',
  'details>summary:first-of-type:not([inert])',
  'details:not([inert])',
];

const candidateSelector = candidateSelectors.join(',');

const isInert = (node: Element | Node | null, lookUp = true) => {
  if (!node) return false;

  // NOTE: tslint was unhappy with `node.inert`. I suspect it's a version thing.
  // TODO: check in after we've updated typescript to the latest version as we might be able to cleanup
  const inert = isHTMLElement(node) ? node.getAttribute('inert') : undefined;

  return (
    inert === '' || inert === 'true' || (lookUp && isInert(node.parentNode))
  );
};

const getCandidates = (
  container: HTMLElement,
  includeContainer = false,
  filter: (el: Element) => boolean
): HTMLElement[] => {
  if (isInert(container)) return [];

  const candidates = Array.from(container.querySelectorAll(candidateSelector));
  if (includeContainer && container.matches(candidateSelector)) {
    candidates.unshift(container);
  }

  return candidates.filter(filter).filter(isHTMLElement);
};

const isHiddenInput = (node: Element) =>
  isInputElement(node) && node.type === 'hidden';

// NOTE: drastically simplified approach from floating UI & tabbable, but seems to work
const isNodeAttached = (node: Element) => document.contains(node);

const isHidden = (node: Element) => {
  if (getComputedStyle(node).visibility === 'hidden') {
    return true;
  }

  const isDirectSummary = node.matches('details>summary:first-of-type');
  const nodeUnderDetails = isDirectSummary ? node.parentElement : node;
  if (nodeUnderDetails && nodeUnderDetails.matches('details:not([open]) *')) {
    return true;
  }

  if (isNodeAttached(node)) {
    return !node.getClientRects().length;
  }

  return false;
};

const isDetailsWithSummary = (node: Element) =>
  isDetailsElement(node) &&
  Array.from(node.children).some(child => child.tagName === 'SUMMARY');

const isDisabledFromFieldset = (node: Element) => {
  if (
    isInputElement(node) ||
    isButtonElement(node) ||
    isSelectElement(node) ||
    isTextAreaElement(node)
  ) {
    let parentNode = node.parentElement;
    // check if `node` is contained in a disabled <fieldset>
    while (parentNode) {
      if (isFieldSetElement(parentNode) && parentNode.disabled) {
        // look for the first <legend> among the children of the disabled <fieldset>
        const firstLegend = Array.from(parentNode.children).find(
          child => child && isLegendElement(child)
        );

        // the disabled <fieldset> containing `node` has no <legend>
        if (!firstLegend) return true;

        // if its parent <fieldset> is not nested in another disabled <fieldset>,
        // return whether `node` is a descendant of its first <legend>
        return parentNode.matches('fieldset[disabled] *')
          ? true
          : !firstLegend.contains(node);
      }
      parentNode = parentNode.parentElement;
    }
  }

  return false;
};

const getCheckedRadio = (nodes: Element[], form: HTMLFormElement | null) =>
  nodes.find(
    node => isInputElement(node) && node.checked && node.form === form
  );

const isTabbableRadio = (node: HTMLInputElement) => {
  if (!node.name) return true;

  const radioScope = node.form || document;
  const queryRadios = (name: string) =>
    Array.from(
      radioScope.querySelectorAll('input[type="radio"][name="' + name + '"]')
    );

  let radioSet: Element[] | undefined;
  if (
    typeof window !== 'undefined' &&
    typeof window.CSS !== 'undefined' &&
    typeof window.CSS.escape === 'function'
  ) {
    radioSet = queryRadios(window.CSS.escape(node.name));
  } else {
    try {
      radioSet = queryRadios(node.name);
    } catch (err) {
      console.error(
        'Looks like you have a radio button with a name attribute containing invalid CSS selector characters and need the CSS.escape polyfill: %s',
        err instanceof Error ? err.message : err
      );
      return false;
    }
  }

  const checked = getCheckedRadio(radioSet, node.form);
  return !checked || checked === node;
};

const isNonTabbableRadio = (node: Element) =>
  isInputElement(node) && node.type === 'radio' && !isTabbableRadio(node);

const getTabIndex = (node: Element) => {
  if (!isHTMLElement(node)) return -1;

  if (node.tabIndex < 0) {
    // in Chrome, <details/>, <audio controls/> and <video controls/> elements get a default
    // `tabIndex` of -1 when the 'tabindex' attribute isn't specified in the DOM,
    // yet they are still part of the regular tab order; in FF, they get a default
    // `tabIndex` of 0; since Chrome still puts those elements in the regular tab
    // order, consider their tab index to be 0.
    // Also browsers do not return `tabIndex` correctly for contentEditable nodes;
    // so if they don't have a tabindex attribute specifically set, assume it's 0.
    if (
      /^(AUDIO|VIDEO|DETAILS)$/.test(node.tagName) ||
      (node.isContentEditable && isNaN(node.tabIndex))
    ) {
      return 0;
    }
  }

  return node.tabIndex;
};

const isDisabled = (node: Element) => {
  if (
    isButtonElement(node) ||
    isFieldSetElement(node) ||
    isInputElement(node) ||
    isLinkElement(node) ||
    isOptGroupElement(node) ||
    isOptionElement(node) ||
    isSelectElement(node) ||
    isTextAreaElement(node)
  ) {
    return node.disabled;
  }
  return false;
};

const isNodeFocusable = (node: Element) => {
  if (
    isDisabled(node) ||
    isInert(node) ||
    isHiddenInput(node) ||
    isHidden(node) ||
    // For a details element with a summary, the summary element gets the focus
    isDetailsWithSummary(node) ||
    isDisabledFromFieldset(node)
  ) {
    return false;
  }
  return true;
};

const isNodeTabbable = (node: Element) => {
  if (
    isNonTabbableRadio(node) ||
    getTabIndex(node) < 0 ||
    !isNodeFocusable(node)
  ) {
    return false;
  }
  return true;
};

const sortByOrder = (els: HTMLElement[]) => {
  const regular: HTMLElement[] = [];
  const ordered: {
    documentOrder: number;
    tabIndex: number;
    el: HTMLElement;
  }[] = [];

  els.forEach((el, i) => {
    const tabIndex = getTabIndex(el);
    if (tabIndex === 0) {
      regular.push(el);
    } else {
      ordered.push({ documentOrder: i, tabIndex, el });
    }
  });

  return ordered
    .sort((a, b) =>
      a.tabIndex === b.tabIndex
        ? a.documentOrder - b.documentOrder
        : a.tabIndex - b.tabIndex
    )
    .map(({ el }) => el)
    .concat(regular);
};

export const tabbable = (container: HTMLElement, includeContainer = false) =>
  sortByOrder(getCandidates(container, includeContainer, isNodeTabbable));

export const focusable = (container: HTMLElement, includeContainer = false) =>
  getCandidates(container, includeContainer, isNodeFocusable);

let rafId = 0;
export const enqueueFocus = (el: HTMLElement) => {
  window.cancelAnimationFrame(rafId);
  rafId = window.requestAnimationFrame(() => el.focus());
};
