import { useState, useEffect, useRef } from 'react';
import type { LayoutProps } from '@odo/lib/styled';
import styled, { compose, layout } from '@odo/lib/styled';
import { isHTMLElement } from '@odo/types/guards';
import type { DialogType } from './types';
import { enqueueFocus, focusable } from '@odo/utils/tabbable';
import FocusTrap from './focus-trap';
import { useCloseOnEscape } from '@odo/components/widgets/dialog';

type DialogContentWrapperProps = LayoutProps;

const DialogContentWrapper = styled.div<DialogContentWrapperProps>`
  max-width: calc(100vw - 20px);
  /* NOTE: we have two max-height declarations */
  /* the latter uses dynamic viewport height which is better for mobile devices but isn't fully supported */
  /* so the former will be used as a fallback when the latter fails */
  max-height: calc(90vh - 20px);
  max-height: calc(100dvh - 20px);

  /* our enter/exit animations */
  transition: transform 100ms ease, opacity 75ms ease;
  @media (prefers-reduced-motion) {
    transition: unset;
  }

  opacity: 0;
  transform: translateY(50px);

  &.active {
    opacity: 1;
    transform: translateY(0px);
  }

  ${compose(layout)}
`;

DialogContentWrapper.defaultProps = {
  overflow: 'auto',
};

const DialogInner = ({
  children,
  labelledById,
  describedById,
  autoFocus,
  isOpen,
  close,
}: DialogType) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const lastActiveElement = useRef<HTMLElement | undefined>();
  const [internalOpen, setInternalOpen] = useState(false);

  useCloseOnEscape({ close, disabled: !isOpen });

  /**
   * Focus first focusable element on open.
   * NOTE: uses internal open as it must wait for the content to be rendered.
   */
  useEffect(() => {
    if (!autoFocus || !internalOpen || !contentRef.current) return;
    const [first] = focusable(contentRef.current);
    if (first) enqueueFocus(first);
  }, [internalOpen, autoFocus]);

  /**
   * Return focus to previously focused element on close.
   * NOTE: uses internal open as it must wait for the content to be removed.
   */
  useEffect(() => {
    if (internalOpen) return;

    if (lastActiveElement.current) {
      enqueueFocus(lastActiveElement.current);
      lastActiveElement.current = undefined;
    }
  }, [internalOpen]);

  /**
   * Show/hide state toggle and exit animation.
   */
  useEffect(() => {
    if (internalOpen === isOpen) return;

    if (isOpen) {
      // capture currently focused element
      if (document.activeElement && isHTMLElement(document.activeElement)) {
        lastActiveElement.current = document.activeElement;
      }

      // render content
      setInternalOpen(true);
    } else {
      // exit animation
      if (wrapperRef.current) {
        const wrapper = wrapperRef.current;
        wrapper.classList.remove('active');
      }

      // wait for transition to finish, then remove
      const timeoutId = setTimeout(() => setInternalOpen(false), 100);

      return () => clearTimeout(timeoutId);
    }
  }, [internalOpen, isOpen]);

  /**
   * Enter animation.
   * NOTE: We want to wait for the DOM node to be rendered before trying to animate it.
   */
  useEffect(() => {
    if (!internalOpen || !wrapperRef.current) return;
    const wrapper = wrapperRef.current;
    wrapper.classList.add('active');
  }, [internalOpen]);

  return (
    <>
      {internalOpen && (
        <DialogContentWrapper
          ref={wrapperRef}
          role="dialog"
          tabIndex={-1}
          aria-labelledby={labelledById}
          aria-describedby={describedById}
          aria-modal="true"
        >
          <FocusTrap>{children}</FocusTrap>
        </DialogContentWrapper>
      )}
    </>
  );
};

export default DialogInner;
