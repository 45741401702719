import {
  ButtonStrip,
  Checkbox,
  InputNumeric,
  InputNumericMasked,
  Select,
} from '@odo/components/elements/form-fields';
import { Flex, Grid, GridItem } from '@odo/components/elements/layout';
import { Text } from '@odo/components/elements/typography';
import ErrorBoundary from '@odo/components/widgets/error-boundary';
import type { AttributeOption } from '@odo/contexts/attributes';
import {
  useChangeProduct,
  useCurrentProduct,
} from '@odo/contexts/product-editor';
import { useAttributeOptions } from '@odo/hooks/attributes';
import SectionWrapper from '@odo/screens/deal/editor/elements/section-wrapper';
import { Overscroll } from '@odo/screens/deal/editor/elements/styles';
import CustomOptions from '@odo/screens/deal/editor/price-and-custom-options/custom-options';
import {
  OriginalStock,
  RebateDiscount,
} from '@odo/screens/deal/editor/widgets/shared-fields';
import { AttributeCode } from '@odo/types/api';
import type { EditorSurcharge } from '@odo/types/portal';
import { cssColor } from '@odo/utils/css-color';
import { useMemo } from 'react';
import { FaCalculator as IconCalculator } from 'react-icons/fa';
import {
  SURCHARGE_INSURANCE_KEY,
  SURCHARGE_WEIGHT_KEY,
} from '@odo/screens/deal/editor/constants';
import Button from '@odo/components/elements/button';
import { calcSurchargeInsurance } from '@odo/helpers/calculations/general';
import { getAdminUrl, validateProduct } from '@odo/screens/deal/editor/helpers';
import {
  priceSectionValidators,
  surchargesSectionValidators,
} from '@odo/screens/deal/editor/price-and-custom-options/validators';
import { formatMoney } from '@odo/utils/currency';
import {
  useSavings,
  useProfitCalculation,
} from '@odo/screens/deal/editor/hooks';
import ButtonLink from '@odo/components/elements/button/link';
import Tooltip from '@odo/components/widgets/tooltip';
import ProfitPotential from '@odo/components/widgets/profit-potential';

type SurchargeValidation = '-' | '+';

const SurchargeInput = ({
  surcharge,
  surchargeType,
  cost,
}: {
  surcharge: EditorSurcharge;
  surchargeType?: AttributeOption;
  cost?: number | null;
}) => {
  const change = useChangeProduct();

  const validation: SurchargeValidation | undefined = useMemo(() => {
    if (!surchargeType) return;

    const meta =
      (surchargeType.metadata || []).find(({ key }) => key === 'validation')
        ?.value || '';

    if (meta === '-') return '-';
    if (meta === '+') return '+';
  }, [surchargeType]);

  return (
    <Grid gap={[2, 3]}>
      <GridItem gridColumn="1" gridRow="1">
        <InputNumericMasked
          label={surcharge.label}
          value={surcharge.value?.string || ''}
          type="currency"
          allowNegative={validation !== '+'}
          selectOnFocus
          disabled={surcharge.id === SURCHARGE_WEIGHT_KEY}
          onChange={nextSurcharge => {
            change({
              fieldId: `surcharges.${surcharge.id}`,
              label: `Surcharge (${surcharge.label})`,
              screen: 'price-and-custom-options',
              apply: to =>
                (to.surcharges = (to.surcharges || []).map(s =>
                  s.id === surcharge.id ? { ...s, value: nextSurcharge } : s
                )),
            });
          }}
          onBlur={() => {
            if (!validation || typeof surcharge.value.number !== 'number') {
              return;
            }

            const value = surcharge.value.number;
            const formatted =
              validation === '-' ? Math.abs(value) * -1 : Math.abs(value);

            if (value !== formatted) {
              change({
                fieldId: `surcharges.${surcharge.id}`,
                label: `Surcharge (${surcharge.label})`,
                screen: 'price-and-custom-options',
                apply: to =>
                  (to.surcharges = (to.surcharges || []).map(s =>
                    s.id === surcharge.id
                      ? {
                          ...s,
                          value: {
                            string: formatted.toString(),
                            number: formatted,
                          },
                        }
                      : s
                  )),
              });
            }
          }}
        />
      </GridItem>

      {/* NOTE: this grid overlay trick is working alright here... but it's less than ideal for repeated usage */}
      {/* TODO: implement a proper solution if we find ourselves needing buttons within inputs more */}
      {surcharge.id === SURCHARGE_INSURANCE_KEY && (
        <GridItem gridColumn="1" gridRow="1" style={{ pointerEvents: 'none' }}>
          <Flex
            height="100%"
            justifyContent="flex-end"
            alignItems="flex-end"
            pb={1}
            pr={1}
          >
            <Button
              hue="blue"
              variant="flat"
              circular
              px={1}
              py={1}
              disabled={typeof cost !== 'number'}
              style={{ pointerEvents: 'all' }}
              onClick={() => {
                if (typeof cost !== 'number') return;
                const insurance = calcSurchargeInsurance(cost);
                change({
                  fieldId: `surcharges.${surcharge.id}`,
                  label: `Surcharge (${surcharge.label})`,
                  screen: 'price-and-custom-options',
                  apply: to =>
                    (to.surcharges = (to.surcharges || []).map(s =>
                      s.id === surcharge.id
                        ? {
                            ...s,
                            value: {
                              string: insurance.toString(),
                              number: insurance,
                            },
                          }
                        : s
                    )),
                });
              }}
            >
              <IconCalculator size="1.75rem" color={cssColor('palette-blue')} />
            </Button>
          </Flex>
        </GridItem>
      )}
    </Grid>
  );
};

const PriceSection = () => {
  const currentProduct = useCurrentProduct();
  const change = useChangeProduct();

  const savings = useSavings();

  const status = validateProduct(currentProduct, priceSectionValidators).status;

  const adminCostOptions = useAttributeOptions(AttributeCode.adminCost);
  const taxClassOptions = useAttributeOptions(AttributeCode.taxClass);

  const adminCostOptionsFiltered = useMemo(
    () =>
      adminCostOptions.filter(
        option =>
          option.value === currentProduct.adminCost?.id ||
          !['none', 'admincost_0_00'].includes(option.value.toLowerCase())
      ),
    [adminCostOptions, currentProduct.adminCost?.id]
  );

  const taxClassOptionsFiltered = useMemo(
    () =>
      taxClassOptions.filter(
        option =>
          !['please_select', 'shipping'].includes(option.key.toLowerCase())
      ),
    [taxClassOptions]
  );

  return (
    <SectionWrapper title="Pricing" status={status}>
      <Grid
        gap="24px"
        gridTemplateColumns={['1fr', 'repeat(2, 1fr)', 'repeat(3, 1fr)']}
      >
        <InputNumericMasked
          label="Cost"
          value={currentProduct.cost?.string || ''}
          type="currency"
          onChange={cost => {
            change({
              fieldId: 'cost',
              label: 'Cost',
              screen: 'price-and-custom-options',
              apply: to => (to.cost = cost),
            });
          }}
          required
          selectOnFocus
        />

        <InputNumericMasked
          label="Price"
          value={currentProduct.price?.string || ''}
          type="currency"
          onChange={price => {
            change({
              fieldId: 'price',
              label: 'Price',
              screen: 'price-and-custom-options',
              apply: to => (to.price = price),
            });
          }}
          required
          selectOnFocus
        />

        <InputNumericMasked
          label="Retail"
          value={currentProduct.retail?.string || ''}
          type="currency"
          onChange={retail => {
            change({
              fieldId: 'retail',
              label: 'Retail',
              screen: 'price-and-custom-options',
              apply: to => (to.retail = retail),
            });
          }}
          selectOnFocus
        />

        <OriginalStock screen="price-and-custom-options" />

        <RebateDiscount screen="price-and-custom-options" disabled />
      </Grid>

      <Select
        label="Admin Cost"
        value={currentProduct.adminCost?.id || ''}
        onChange={e => {
          const value = e.target.value;
          const adminCost = adminCostOptions.find(
            adminCost => adminCost.value === value
          );
          change({
            fieldId: 'adminCost',
            label: 'Admin Cost',
            screen: 'price-and-custom-options',
            apply: to => {
              to.adminCost = adminCost
                ? {
                    id: adminCost.value,
                    label: adminCost.label,
                  }
                : undefined;
            },
          });
        }}
        options={[
          { id: '', value: '', label: 'Please select...' },
          ...adminCostOptionsFiltered.map(option => ({
            id: option.value,
            value: option.value,
            label: option.label,
          })),
        ]}
        required
      />

      <Tooltip
        placement="bottom-start"
        color={cssColor('palette-yellow')}
        showDelay={250}
        content={() =>
          'NONE can only be selected for VAT exempt products or International Travel.'
        }
      >
        <ButtonStrip
          label="Tax Class"
          hue="blue"
          selectedOption={{
            id: currentProduct.taxClass?.id || '',
            label: currentProduct.taxClass?.label || '',
          }}
          options={taxClassOptionsFiltered.map(option => ({
            id: option.value,
            label: option.label,
          }))}
          onSelect={option => {
            change({
              fieldId: 'taxClass',
              label: 'Tax Class',
              screen: 'price-and-custom-options',
              apply: to =>
                (to.taxClass = { id: option.id, label: option.label }),
            });
          }}
        />
      </Tooltip>

      {!!savings ? (
        <ButtonStrip
          label="Customer Savings in Rands/Percentage"
          hue="blue"
          selectedOption={
            currentProduct.isSavingsInRands
              ? { id: 'rands', label: 'Rands' }
              : { id: 'percentage', label: 'Percentage' }
          }
          options={[
            {
              id: 'rands',
              label: `Save ${formatMoney(savings.fixed, { decimals: 0 })}`,
            },
            { id: 'percentage', label: `Save ${savings.percentage}%` },
          ]}
          onSelect={option => {
            change({
              fieldId: 'isSavingsInRands',
              label: 'Customer Savings in Rands/Percentage',
              screen: 'price-and-custom-options',
              apply: to => (to.isSavingsInRands = option.id === 'rands'),
            });
          }}
        />
      ) : (
        <Checkbox
          label="Display Savings In Rands?"
          checked={!!currentProduct.isSavingsInRands}
          onChange={e => {
            const checked = !!e.target.checked;
            change({
              fieldId: 'isSavingsInRands',
              label: 'Display Savings In Rands?',
              screen: 'price-and-custom-options',
              apply: to => (to.isSavingsInRands = checked),
            });
          }}
        />
      )}

      <Checkbox
        label="Show Retail Price on Deal?"
        checked={!!currentProduct.isDisplayRetail}
        onChange={e => {
          const checked = !!e.target.checked;
          change({
            fieldId: 'isDisplayRetail',
            label: 'Show Retail Price on Deal?',
            screen: 'price-and-custom-options',
            apply: to => (to.isDisplayRetail = checked),
          });
        }}
      />
    </SectionWrapper>
  );
};

const SurchargesSection = () => {
  const currentProduct = useCurrentProduct();

  const status = validateProduct(
    currentProduct,
    surchargesSectionValidators
  ).status;

  const surchargeTypeOptions = useAttributeOptions(AttributeCode.surcharges);

  return (
    <SectionWrapper title="Surcharges" status={status}>
      <Grid gap="24px" gridTemplateColumns={['1fr', 'repeat(2, 1fr)']}>
        {(currentProduct.surcharges || []).map(surcharge => (
          <SurchargeInput
            key={surcharge.id}
            surcharge={surcharge}
            surchargeType={surchargeTypeOptions.find(
              option => option.key === surcharge.id
            )}
            cost={currentProduct.cost?.number}
          />
        ))}

        <GridItem
          gridColumn={[
            '1',
            // if we have an even number of surcharges, make the total span 2 columns from tablet up
            (currentProduct.surcharges || []).length % 2 === 0 ? '1/3' : '2',
          ]}
        >
          <InputNumeric
            label="Total"
            value={currentProduct.surcharge?.toString() || ''}
            prefix="R"
            disabled
          />
        </GridItem>
      </Grid>
    </SectionWrapper>
  );
};

const CalculatorSection = () => {
  const currentProduct = useCurrentProduct();
  const profit = useProfitCalculation();

  return (
    <SectionWrapper
      title="Price Calculator"
      headerExtras={
        !!currentProduct.id && (
          <ButtonLink
            hue="blue"
            variant="solid"
            target="_blank"
            href={getAdminUrl({
              dest: 'priceCalculator',
              productId: currentProduct.id,
            })}
          >
            ODO Admin Price Calculator
          </ButtonLink>
        )
      }
    >
      {!!profit && <ProfitPotential profit={profit} />}

      {!profit && (
        <Text>
          Profit potential will be calculated after filling in cost, price, and
          original stock.
        </Text>
      )}
    </SectionWrapper>
  );
};

const CustomOptionsSection = () => (
  <SectionWrapper title="Custom Options" overflow>
    <CustomOptions />
  </SectionWrapper>
);

const PriceAndCustomOptionsScreen = () => (
  <ErrorBoundary>
    <Grid gridTemplateColumns={['1fr', '1fr 1fr']} gap={[3, 4]}>
      <PriceSection />

      <Grid gap={[3, 4]} alignContent="flex-start">
        <SurchargesSection />
        <CalculatorSection />
      </Grid>

      <GridItem gridColumn={['1/2', '1/3']}>
        <CustomOptionsSection />
      </GridItem>
    </Grid>
    <Overscroll />
  </ErrorBoundary>
);

export default PriceAndCustomOptionsScreen;
