import Button from '@odo/components/elements/button';
import { Input } from '@odo/components/elements/form-fields';
import { Grid } from '@odo/components/elements/layout';
import type { Dispatch, SetStateAction } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import Tooltip from '@odo/components/widgets/tooltip';
import { error } from '@odo/utils/toast';
import type {
  DisplayedResult,
  GoogleSearchResult,
} from '@odo/screens/tools/cost-compare/types';
import {
  isValidOrganicSearchResult,
  isValidScraperResponse,
  transformOrganicToDisplay,
} from '@odo/screens/tools/cost-compare/types';
import {
  SCRAPER_AUTH_TOKEN,
  SCRAPER_URL,
  SCRAPE_PAGE_COUNT,
} from '@odo/screens/tools/cost-compare/constants';
import type { CompareResultsInheritedProps } from './compare-results';
import CompareResults from './compare-results';

const search = async ({
  term,
  pages,
  signal,
  setIsLoading,
  addResults,
}: {
  term: string;
  pages: number;
  signal: AbortSignal;
  setIsLoading: (val: boolean) => void;
  addResults: (res: GoogleSearchResult, page: number) => void;
}) => {
  if (!SCRAPER_URL || !SCRAPER_AUTH_TOKEN) {
    error('Scraper URL or AUTH TOKEN missing.');
    return;
  }

  let isActive = true;
  setIsLoading(true);

  signal.addEventListener('abort', () => (isActive = false));

  try {
    for (let page = 1; page <= pages; page++) {
      const res = await fetch(SCRAPER_URL, {
        signal,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Basic ${SCRAPER_AUTH_TOKEN}`,
        },
        body: JSON.stringify({
          // search params
          source: 'google_search',
          query: term,

          // pagination
          start_page: page,

          // other stuff
          parse: true,
          geo_location: 'South Africa',
          locale: 'en-za',
          domain: 'co.za',
        }),
      });

      // exit if aborted while we were awaiting
      if (!isActive) return;

      const json: unknown = await res.json();

      // same again because we awaited again
      if (!isActive) return;

      if (isValidScraperResponse(json)) {
        addResults(json.results[0].content.results, page);
      }
    }
  } catch (e) {
    console.error(e);
    error(
      `Error with OxyLabs API: "${
        e instanceof Error && typeof e.message === 'string' ? e.message : 'N/A'
      }"`
    );
  } finally {
    setIsLoading(false);
  }
};

export interface CompareFormInheritedProps {
  initialTerm?: string;
  autoSearch?: boolean;
}

const CompareForm = ({
  initialTerm,
  autoSearch,
  setResults,
  setLastPageLoaded,
}: CompareFormInheritedProps & {
  setResults: Dispatch<SetStateAction<DisplayedResult[]>>;
  setLastPageLoaded: (page: number) => void;
}) => {
  const hasAutoSearched = useRef(false);

  const [term, setTerm] = useState(initialTerm);
  const [activeTerm, setActiveTerm] = useState<string | undefined>();

  const isCurrentTermActive = term === activeTerm;

  const [isLoading, setIsLoading] = useState(false);

  const controllerRef = useRef<AbortController>();

  const compare = useCallback(
    (term: string) => {
      if (controllerRef.current) controllerRef.current.abort();

      let newResults = true;
      const resultKeys: string[] = [];
      setActiveTerm(term);

      controllerRef.current = new AbortController();

      search({
        term,
        pages: SCRAPE_PAGE_COUNT,
        signal: controllerRef.current.signal,
        setIsLoading,
        addResults: (res: GoogleSearchResult, page: number) => {
          const clearPrev = newResults;
          newResults = false;

          const formatted = res.organic
            // remove invalid results
            .filter(isValidOrganicSearchResult)
            // transform results into more usable data
            .map(transformOrganicToDisplay)
            // remove duplicates (due to google pagination issues)
            .filter(res => {
              const key = `${res.title}.${res.url}`;
              if (resultKeys.includes(key)) return false;
              resultKeys.push(key);
              return true;
            });

          setResults(res => [...(!clearPrev ? res : []), ...formatted]);
          setLastPageLoaded(page);
        },
      });
    },
    [setResults, setLastPageLoaded]
  );

  /**
   * Trigger search automatically when required.
   */
  useEffect(() => {
    if (!autoSearch || hasAutoSearched.current || !term) return;
    hasAutoSearched.current = true;
    compare(term);
  }, [autoSearch, term, compare]);

  return (
    <Grid
      gap={[2, 3]}
      alignItems="flex-end"
      gridTemplateColumns={['1fr', '1fr auto']}
    >
      <Input
        label="Search Term"
        value={term}
        onChange={e => setTerm(e.target.value)}
      />

      <Tooltip
        disabled={!isCurrentTermActive}
        content={() => 'Already comparing this search term'}
      >
        <Button
          hue="blue"
          variant="outlined"
          loading={isLoading}
          disabled={!term || isCurrentTermActive}
          onClick={() => term && compare(term)}
        >
          Compare
        </Button>
      </Tooltip>
    </Grid>
  );
};

type CostCalculatorProps = CompareFormInheritedProps &
  CompareResultsInheritedProps;

const CostCompare = ({
  initialTerm,
  autoSearch,
  setRetail,
}: CostCalculatorProps) => {
  const [results, setResults] = useState<DisplayedResult[]>([]);
  const [lastPageLoaded, setLastPageLoaded] = useState<number | undefined>();

  return (
    <Grid gap="24px">
      <CompareForm
        initialTerm={initialTerm}
        autoSearch={autoSearch}
        setResults={setResults}
        setLastPageLoaded={setLastPageLoaded}
      />

      {results.length > 0 && (
        <CompareResults
          setRetail={setRetail}
          results={results}
          lastPageLoaded={lastPageLoaded}
        />
      )}
    </Grid>
  );
};

export default CostCompare;
